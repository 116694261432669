<template>
  <div>
    <vue-good-table :columns="columns" :rows="answersList" :rtl="direction">
      <div slot="emptystate" class="text-center">
        Заявки отсутсвуют
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";

import store from "@/store/index";

export default {
  components: {
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: ["answersList"],
  data() {
    return {
      dir: false,
      columns: [
        {
          label: "ID",
          field: "displayId",
        },
        {
          label: "Дата заявки",
          field: "timeAt",
        },
        {
          label: "Название проекта",
          field: "project",
        },
        {
          label: "Лид-форма",
          field: "leadForm",
        },
        {
          label: "Имя",
          field: "name",
        },
        {
          label: "Телефон",
          field: "phone",
        },
        {
          label: "E-mail",
          field: "email",
        },
        {
          label: "Текстовое поле",
          field: "string",
        },
      ],
      answers: [
        {
          timeAt: "123",
          question: "kek",
          answer: "lol",
          projectId: 123,
          project: "test",
          leadForm: "Test",
        },
      ],
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
};
</script>

<style lang="scss"></style>
