<template>
  <div>
    <div class="custom-search mb-2">
      <b-row>
        <b-col md="2">
          <label for="lead-type"> Выберите лид форму: </label>
          <b-form-select
            id="lead-type"
            v-model="form.type"
            @change="filterByLeadForm"
            :options="leadFormsList"
          >
          </b-form-select>
        </b-col>
        <b-col md="2">
          <label for="lead-type"> Выберите даты: </label>
          <flat-pickr
            class="form-control"
            placeholder="..."
            v-model="dateRange"
            :config="configDateRange"
            @on-change="dateRangeChanged"
            @on-close="dateRangeSelected"
          />
        </b-col>
        <b-col class="align-self-end text-right">
          <download-excel
            :fetch="fetchData"
            :fields="excelFields"
            class="btn btn-outline-primary"
            v-if="!getAnswersObj.isDisabled"
          >
            Выгрузить в Excel
            <feather-icon icon="FileTextIcon" />
          </download-excel>
        </b-col>
      </b-row>
    </div>
    <answers-table :answersList="answersList" />
    <div class="text-center">
      <b-button
        @click="loadMore"
        :disabled="getAnswersObj.isEnded || getAnswersObj.isDisabled"
        variant="outline-primary w-100 mt-2"
      >
        Загрузить ещё
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BForm,
  BRow,
  BCol,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";
import { Russian } from "flatpickr/dist/l10n/ru.js";

import AnswersTable from "./AnswersTable.vue";

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BForm,
    BRow,
    BCol,
    AnswersTable,
    flatPickr,
  },
  data() {
    return {
      form: {},
      dateRange: null,
      configDateRange: {
        minDate: null,
        maxDate: new Date(),
        locale: Russian,
        mode: "range",
      },
      isDateRangeChanged: false,
      initRequest: {
        dateStart: null,
        dateEnd: null,
      },
      answersList: null,
      dataForExcel: [
        { colA: "Hello", colB: "World" },
        {
          colA: "Multi-line",
          /* Multi-line value: */
          colB:
            "This is a long paragraph\nwith multiple lines\nthat should show in a single cell.",
        },
        { colA: "Another", colB: "Regular cell" },
      ],
      excelFields: {
        "ID заявки": "displayId",
        "Дата заявки": "timeAt",
        Проект: "project",
        "Лид-форма": "leadForm",
        Имя: "name",
        Телефон: "phone",
        "E-mail": "email",
      },
    };
  },
  metaInfo() {
    return {
      title: "moovee - Заявки",
    };
  },
  computed: {
    ...mapGetters("lead-forms", ["getFormsList", "getAnswersObj"]),
    ...mapGetters("projects", ["getProjects"]),
    leadFormsList() {
      const list = this.getFormsList.map((form) => {
        return {
          value: form.id,
          text: form.name,
        };
      });
      list.push({ value: null, text: "Все" });
      return list;
    },
    getAnswersList() {
      const list = [];
      let answersObj = this.getAnswersObj.res;
      const isDisabled = this.getAnswersObj.isDisabled;
      if (answersObj)
        answersObj.forEach((answerObj) => {
          const ans = {};
          ans.timeAt =
            new Date(answerObj.timeAt.seconds * 1000).toLocaleDateString(
              "ru-RU"
            ) +
            " " +
            answerObj.timeAt.toDate().toLocaleTimeString("ru-RU");
          ans.project = isDisabled
            ? "*****"
            : this.findProjectName(answerObj.projectId);
          ans.leadForm = this.findFormName(answerObj.leadFormId);
          ans.displayId = answerObj.displayId;
          for (const [key, value] of Object.entries(answerObj.answers)) {
            ans[this.findQuestionType(answerObj.leadFormId, key)] = isDisabled
              ? "*****"
              : value;
          }
          list.push(ans);
        });
      return list;
    },
  },
  watch: {
    getAnswersList(newValue) {
      this.answersList = newValue;
    },
  },
  methods: {
    ...mapActions("lead-forms", [
      "requestForms",
      "requestLeadForm",
      "saveAnswers",
      "requestAnswersObj",
      "requesetMoreAnswers",
      "requestFullAnswers",
    ]),
    ...mapActions("projects", ["requestProjects"]),
    async fetchData() {
      const form = this.form.type;
      const startDate = null;
      const endDate = null;
      if (this.initRequest.dateStart)
        startDate = new Date(this.initRequest.dateStart);
      if (this.initRequest.dateEnd)
        endDate = new Date(this.initRequest.dateEnd);
      const fullList = await this.requestFullAnswers({
        form,
        startDate,
        endDate,
      });
      const ansList = this.getListForExcel(fullList);
      return ansList;
    },
    getListForExcel(input) {
      const list = [];
      let answersObj = input;
      if (answersObj)
        answersObj.forEach((answerObj) => {
          const ans = {};
          ans.timeAt =
            new Date(answerObj.timeAt.seconds * 1000).toLocaleDateString(
              "ru-RU"
            ) +
            " " +
            answerObj.timeAt.toDate().toLocaleTimeString("ru-RU");
          ans.project = this.findProjectName(answerObj.projectId);
          ans.leadForm = this.findFormName(answerObj.leadFormId);
          ans.displayId = answerObj.displayId;
          for (const [key, value] of Object.entries(answerObj.answers)) {
            ans[this.findQuestionType(answerObj.leadFormId, key)] = value;
          }
          list.push(ans);
        });
      return list;
    },
    findFormName(id) {
      const res = this.getFormsList.find((form) => {
        return id === form.id;
      });
      if (res) return res.name;
      else return "-";
    },
    findQuestionName(formId, id) {
      const form = this.getFormsList.find((form) => {
        return formId === form.id;
      });
      const res = form.questions.find((question) => {
        return question.id === id;
      });
      if (res) return res.title;
      else return null;
    },
    findQuestionType(formId, id) {
      const form = this.getFormsList.find((form) => {
        return formId === form.id;
      });
      let res = {};
      if (form)
        res = form.questions.find((question) => {
          return question.id === id;
        });
      else return "-";
      if (res) return res.type;
      else return null;
    },
    findProjectName(projectId) {
      const res = this.getProjects.find((project) => project.id === projectId);
      if (res) return res.name;
      return null;
    },
    dateRangeSelected(selectedDates) {
      if (this.isDateRangeChanged) {
        this.isDateRangeChanged = false;
        this.initRequest.dateStart = selectedDates[0]
          .toISOString()
          .substring(0, 10);
        if (selectedDates[1]) {
          const tomorrowArr = JSON.parse(JSON.stringify(selectedDates));
          const tomorrow = new Date(tomorrowArr[1]);
          tomorrow.setDate(tomorrow.getDate() + 1);
          this.initRequest.dateEnd = tomorrow.toISOString().substring(0, 10);
        }
        this.filterByDates();
      }
    },
    filterByLeadForm() {
      const form = this.form.type;
      const startDate = null;
      const endDate = null;
      if (this.initRequest.dateStart)
        startDate = new Date(this.initRequest.dateStart);
      if (this.initRequest.dateEnd)
        endDate = new Date(this.initRequest.dateEnd);
      this.requestAnswersObj({ form, startDate, endDate });
    },
    filterByDates() {
      const startDate = new Date(this.initRequest.dateStart);
      const endDate = new Date(this.initRequest.dateEnd);
      this.requestAnswersObj({ startDate, endDate });
    },
    dateRangeChanged() {
      this.isDateRangeChanged = true;
    },
    loadMore() {
      this.requesetMoreAnswers({
        lastVisible: this.getAnswersObj.lastVisible,
        request: this.getAnswersObj.request,
      });
      this.pointer += this.stackSize;
    },
  },
  created() {
    this.requestForms();
    this.requestAnswersObj();
    this.requestProjects();
    this.answersList = this.getAnswersList;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
